import React from 'react';
import { render } from 'preact';
import { SharingComponent } from 'shared/engagement-panel';

export class EngagementPanel extends React.Component {

    state = {
        likes: this.props.likes,
        shares: this.props.shares,
        userHasLiked: this.props.engagementFunctions.hasUserLiked(this.props.itemId),
        shareDialogIsOpen: false
     };


    onLikeClick = (sender) => {
        this.props.engagementFunctions.onLike(this.props.itemId, this.onLikeProcessed);
    }

    onShareClick = (sender) => {
        render(
            <SharingComponent
                shareUrl={this.props.itemUrl}
                engagementSettings={this.props.engagementFunctions.engagementSettings}
                onShareCloseClick={this.onShareCloseClick}
                onShared={this.onShared}></SharingComponent>
            ,
            this.props.engagementModalPlaceholder
        );
    }

    onShareCloseClick = () => {
        this.props.engagementModalPlaceholder.innerHTML = "";
    }

    onShared = () => {
        this.props.engagementFunctions.onShare(this.props.itemId);
    }

    onLikeProcessed = (likeResponse) => {
        if (!isNaN(likeResponse?.likeCount)) {
            this.setState({ likes: likeResponse.likeCount, userHasLiked: likeResponse.like });
        }
    }

    render() {

        var likeClass = this.state.userHasLiked ? "liked" : "";
        var likeIconUrl = this.props.engagementFunctions.getLikeIconUrl();
        var shareIconUrl = this.props.engagementFunctions.getShareIconUrl();

        return (
            <div class="engagement_panel">

                {/*<!-- Like button -->*/}
                <div class="engagement_like">
                    <img alt="Like this item" data-item-id={this.props.itemId} src={likeIconUrl} class={likeClass} onclick={this.onLikeClick} />
                    <p><span class="engagement_like_count">{this.state.likes}</span><span> likes</span></p>
                </div>

                {/*<!-- Share button -->*/}
                <div class="engagement_share">
                    <img alt="Share this item" class="engagement_share_button" src={shareIconUrl} onclick={this.onShareClick} />
                    <p><span class="engagement_share_count">{this.state.shares}</span><span> shares</span></p>
                </div>

            </div>
        );

    }

}
