
import Cookies from 'js-cookie';

import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
import { baseApi } from 'project/api';

const ENGAGEMENT_LIKE = getApiUrlByName(apiNames.ENGAGEMENT_LIKE);
const ENGAGEMENT_SHARE = getApiUrlByName(apiNames.ENGAGEMENT_SHARE);

export class EngagementFunctions {

    engagementSettings;
    likedItems;

    constructor(engagementSettings) {
        this.engagementSettings = engagementSettings;
        this.likedItems = this.getLikedItems(this.engagementSettings.likesCookieName);
    }

    getLikedItems = ((cookieName) => {
        var cookieValue = Cookies.get(this.engagementSettings.likesCookieName);
        var intArray = this.splitPipeSeparatedIntegers(cookieValue);
        return intArray;
    });

    getLikeIconUrl = () => {
        return this.engagementSettings.likeIconUrl;
    }

    getShareIconUrl = () => {
        return this.engagementSettings.shareIconUrl;
    }

    hasUserLiked (itemId){
        return this.likedItems.includes(itemId);
    }

    splitPipeSeparatedIntegers = ((input) => {
        if (input != undefined) {
            return input
                .split('|')                   // Split by '|'
                .map(num => parseInt(num, 10)) // Convert to integer
                .filter(num => !isNaN(num));   // Remove NaN values
        }
        else {
            return [];
        }
    });

    onLike(itemId, callback) {

        //  update server side like count
        var likeRequest = {};
        likeRequest.itemId = itemId;
        likeRequest.like = !this.hasUserLiked(itemId);
        likeRequest.className = this.engagementSettings.className;

        this.submitLike(likeRequest).then((response) => {

            if (response != undefined && response.processed) {

                if (likeRequest.like) {
                    this.likedItems.push(itemId);
                }
                else {
                    const index = this.likedItems.indexOf(itemId);
                    if (index > -1) {
                        this.likedItems.splice(index, 1);
                    }
                }

                callback(response);

            }

        });
    }

    onShare(itemId, callback) {
        //  update server side share count
        var shareRequest = {};
        shareRequest.itemId = itemId;
        shareRequest.className = this.engagementSettings.className;

        this.submitShare(shareRequest).then((response) => {

            if (response != undefined && response.processed && callback != undefined) {
                callback(response);
            }

        });
    }

    submitLike = (likeRequest) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(ENGAGEMENT_LIKE, JSON.stringify(likeRequest), config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

    submitShare = (shareRequest) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(ENGAGEMENT_SHARE, JSON.stringify(shareRequest), config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }

}
