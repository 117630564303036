import React from 'react';
import { render } from 'preact';
import { EngagementPanel } from 'shared/engagement-panel';

export class PledgeCard extends React.Component {

    pledge = this.props.pledge;
    engagementFunctions = this.props.engagementFunctions;

    render(){

        var pledge = this.props.pledge;

        var imageUrl = "";
        var imageAlt = "";
        if (pledge.image != undefined && pledge.image.url.length > 0) {
            imageUrl = pledge.image.url + ";name=small";
            imageAlt = "Image for pledge ID " + pledge.pledgeId;
        }

        return (
            <div class="dw_pledge_card"
                key={pledge.pledgeId}
                data-pledge-date={pledge.dateCreated}
                data-pledge-description={pledge.pledgeText}
                data-pledge-id={pledge.pledgeId}
                data-pledge-url={pledge.pledgeUrl}>

                <div class="dw_pledge_title"><span aria-hidden="true" class="dw_pledge_id">{pledge.pledgeId}</span>
                    <h2>{pledge.pledgeHeading}</h2>
                </div>

                {
                    (imageUrl.length > 0) &&
                    <img alt={imageAlt} class="dw_pledge_main_image" src={imageUrl} />
                }

                <p class="dw_pledge_content" dangerouslySetInnerHTML={{ __html: this.textToHtml(pledge.pledgeText) }}></p>

                <p class="dw_pledge_name"><span class="visually-hidden">Pledge made by:</span> {pledge.personName}</p>

                <div class="dw_pledge_tags">
                    {
                        pledge.tags?.map((tag) => {
                            return (<span data-tag={tag}>#{tag}</span>)
                        })
                    }
                </div>

                <EngagementPanel
                    key={pledge.pledgeId}
                    engagementFunctions={this.engagementFunctions}
                    likes={pledge.likes}
                    shares={pledge.shares}
                    itemId={pledge.pledgeId}
                    itemUrl={pledge.pledgeUrl}
                    engagementModalPlaceholder={this.props.engagementModalPlaceholder}
                />

            </div>
        );
    }

    textToHtml = (text) => {
        return text
            .split(/\r\n\r\n/)  // Split by double line breaks
            .map(para => `<p>${para.replace(/\r\n/g, '<br>')}</p>`) // Wrap each paragraph in <p> tags
            .join(''); // Join without extra spaces
    }

}
