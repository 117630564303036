import React from 'react';
import { render } from 'preact';

import { BaseComponent } from 'project/general';
import { BaseForm, formConstants } from 'shared/base-form';
import { notificationTypes } from 'project/general';
import { notificationService } from 'project/services';

import { EngagementFunctions } from 'shared/engagement-panel';
import { PledgeCard } from './pledge-card';
import { ContentCard } from './content-card';

export class PledgeWallComponent extends BaseComponent {

    formInstance = undefined;
    pledges = undefined;
    likedPledges = undefined;
    pageSize = 10;
    totalRecords = 0;
    renderedPledgeCount = 0; // tracks how many pledges have been rendered between pages of results
    contentCardIndex = 0; // tracks index of last inserted content card between pages of results

    static getNamespace() {
        return 'pledge-wall';
    }

    static getRequiredRefs() {
        return [
            'resultsPlaceholder',
            'sortOptionsDropdown',
            'tagFilterDdropdown',
            'skipField',
            'btnMoreResults',
            'engagementModalPlaceholder',
            'pledgeListingForm'
        ];
    }

    onInit() {
/*        this.populateFormFromUrl(this.refs.pledgeListingForm);*/
        this.initForm();
        this.onSubmit();
        this.intialisePledgeTracker();
        this.initialiseWebForm();
        this.reformatWebForm(false);

        //  share across all pledge cards
        this.engagementFunctions = new EngagementFunctions(this.options.engagementParameters);
        this.pageSize = this.options.pageSize;
        this.contentCards = this.options.contentCards;
    }

    initForm() {
        
        var form = this.refs.pledgeListingForm;

        this.formInstance = new BaseForm(form, {
            errorsSummary: document.getElementById("data-errors-summary"),
            onBeforeSubmit: () => { },
            onSuccessfulSubmit: (response) => {

                var pledges = response.data.data.pledges;

                if (this.contentCards != undefined) {
                    //  inject the content cards into the result
                    pledges = this.insertContentCards(pledges, this.contentCards);
                }

                if (Array.isArray(this.pledges)) {
                    this.pledges = this.pledges.concat(pledges);
                }
                else {
                    this.pledges = pledges;
                }

                this.TotalRecords = response.data.data.totalRecords;

                this.toggleMoreResultsButtonVisibility();

                this.OnRenderPledges(this.pledges);

            },
            onFailedSubmit: (error) => {
                notificationService.push({
                    message: "There was an error",
                    type: notificationTypes.ERROR,
                });
            },
            enctype: formConstants.ENCTYPE_JSON
        });

        this.refs.sortOptionsDropdown.addEventListener("change", this.onArgumentsChanged);
        this.refs.tagFilterDdropdown.addEventListener("change", this.onArgumentsChanged);
        this.refs.btnMoreResults.addEventListener("click", this.onMoreResultsClick);
    }
   
     insertContentCards = (pledges, contentCards) => {
        if (!Array.isArray(pledges) || !Array.isArray(contentCards) || contentCards.length === 0) {
            throw new Error("Invalid input: Both pledges and contentCards should be non-empty arrays.");
        }

        let result = [];
       

         for (let i = 0; i < pledges.length; i++) {

             var pledge = pledges[i];
             result.push(pledge);

            // Insert content card after every 5 pledges
             if ((this.renderedPledgeCount + 1) % 5 === 0 && this.renderedPledgeCount !== 0) {
                 var contentCard = contentCards[this.contentCardIndex];
                contentCard.id = "contentCard" + i
                contentCard.type = "contentCard";
                result.push(contentCard);
                 this.contentCardIndex = (this.contentCardIndex + 1) % contentCards.length; // Cycle through contentCards
             }

             this.renderedPledgeCount += 1;
        }

        return result;
    }

    onSubmit = () => {
        this.formInstance.onSubmit();
    }

    onArgumentsChanged = () => {
        this.renderedPledgeCount = 0;
        this.contentCardIndex = 0;
        this.pledges = undefined;
        this.refs.skipField.value = 0;
        this.formInstance.populateUrlParams();
        this.onSubmit();
    }

    toggleMoreResultsButtonVisibility = () => {
        var hiddenClass = "hidden";
        if (this.renderedPledgeCount < this.TotalRecords) {
            if (this.refs.btnMoreResults.classList.contains(hiddenClass)) {
                this.refs.btnMoreResults.classList.remove(hiddenClass);
            }
        }
        else {
            if (!this.refs.btnMoreResults.classList.contains(hiddenClass)) {
                this.refs.btnMoreResults.classList.add(hiddenClass);
            }
        }
    }

    onMoreResultsClick = () => {
        var value = parseInt(this.refs.skipField.value);
        if (isNaN(value) || value == undefined) {
            value = 0;
        }
        this.refs.skipField.value = (value + this.pageSize);
        this.onSubmit();
    }

    OnRenderPledges(pledges) {
        render(
            <div class="dw_pledge_wall">
                {
                    pledges?.map((pledge) => {
                        if (pledge.type == "contentCard") {
                            return (<ContentCard
                                key={pledge.id}
                                contentCard={pledge}></ContentCard>)
                        }
                        else {
                            return (<PledgeCard
                                key={pledge.pledgeId}
                                pledge={pledge}
                                engagementFunctions={this.engagementFunctions}
                                engagementModalPlaceholder={this.refs.engagementModalPlaceholder} />
                            )
                        }
                    })
                }
            </div>
            ,
            this.refs.resultsPlaceholder
        );
    }

    intialisePledgeTracker = () => {

        const pledgeWall = this.element;
        const pledgeCount = parseInt(pledgeWall.dataset.pledgeCount, 10);
        const pledgeTarget = parseInt(pledgeWall.dataset.pledgeCountTarget, 10);
        const pledgeProgress = document.querySelector(".dw_pledge_progress");
        const pledgeCountDisplay = document.querySelector(".dw_pledge_count");

        // Calculate the progress percentage
        const progressPercentage = Math.min((pledgeCount / pledgeTarget) * 100, 100);

        // Animate the progress bar
        setTimeout(() => {
            pledgeProgress.style.width = progressPercentage + "%";
        }, 100); // Small delay for better animation effect

        // Animate the count text
        let currentCount = 0;
        const increment = Math.ceil(pledgeCount / 100); // Increment value for animation

        const counter = setInterval(() => {
            currentCount += increment;
            if (currentCount >= pledgeCount) {
                currentCount = pledgeCount;
                clearInterval(counter);
            }
            pledgeCountDisplay.textContent = this.addCommas(currentCount.toLocaleString());
        }, 15);
    }

    addCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    initialiseWebForm = () => {
        const modal = document.getElementById("dw_pledge_modal");
        const modalContent = document.querySelector(".dw_pledge_modal_content");

        if (!modal || !modalContent) {
            console.error("Modal or modal content placeholder not found.");
            return;
        }

        // Open modal when clicking submit button
        const submitButton = document.getElementById("dw_pledge_submit");
        if (submitButton) {
            submitButton.addEventListener("click", (event) => {
                //this.moveKenticoForm();
                modal.style.display = "block";
            });
        }

        // Close modal
        const closeModal = document.querySelector(".dw_pledge_close");
        if (closeModal) {
            closeModal.addEventListener("click", function () {
                modal.style.display = "none";
            });
        }

        // Close modal when clicking outside or pressing Escape
        window.addEventListener("click", function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        });

        window.addEventListener("keydown", function (event) {
            if (event.key === "Escape") {
                modal.style.display = "none";
            }
        });

        // Initial attempt to move the form in case it's already loaded
       this.moveKenticoForm();


    }

    reformatWebForm = (resetFormState) => {
        const modal = document.getElementById("dw_pledge_modal"); // The modal container
        const modalForm = document.querySelector(".dw_pledge_modal_content .form-widget");
        const inputField = document.getElementById("dw_pledge_input"); // User's pledge input
        const submitButton = document.getElementById("dw_pledge_submit"); // Button to open modal

        if (modalForm) {


            // Select sections
            const section1 = modalForm.querySelector(".ktc-default-section:nth-child(1)"); // form wizard page 1 // Pledge text and tags
            const section2 = modalForm.querySelector(".ktc-default-section:nth-child(2)"); // form wizard page 2 // name fields
            const section3 = modalForm.querySelector(".ktc-default-section:nth-child(3)"); // form wizard page 2 // contact info fields
            const section4 = modalForm.querySelector(".ktc-default-section:nth-child(4)"); // form wizard page 2 // terms & captcha

            const submitFormButton = modalForm.querySelector(".form-widget__submit");

            // Get the "Your Pledge" text area
            const pledgeMessageField = modalForm.querySelector("textarea[name*='PledgeText']");

            // Create Next button
            const nextButton = document.createElement("button");
            nextButton.textContent = "Next";
            nextButton.type = "button";
            nextButton.classList.add("form-next");
            section1.appendChild(nextButton);

            // Create Back button
            const backButton = document.createElement("button");
            backButton.textContent = "Back";
            backButton.type = "button";
            backButton.classList.add("form-back");
            backButton.style.display = "none"; // Initially hidden
            submitFormButton.insertAdjacentElement("beforebegin", backButton); // Insert before submit button


            // Function to reset form state (always starts at Section 1)
            function resetFormState() {

                var section1isValid = detectValidationError(section1);
                var section2isValid = detectValidationError(section2);
                var section3isValid = detectValidationError(section3);
                var section4isValid = detectValidationError(section4);

                if (section1isValid && section2isValid && section3isValid && section4isValid) {
                    showPage1();
                }
                else if (!section1isValid) {
                    showPage1();
                }
                else if (!section2isValid || !section3isValid || !section4isValid) {
                    showPage2();
                }
            }

            function showPage1() {
                section1.style.display = "block";  // Show first section
                section2.style.display = "none";   // Hide second section
                section3.style.display = "none";   // Hide third section
                section4.style.display = "none";   // Hide fourth section
                submitFormButton.style.display = "none"; // Hide submit button
                backButton.style.display = "none"; // Hide back button
            }

            function showPage2() {
                section1.style.display = "none";
                section2.style.display = "block";
                section3.style.display = "block";
                section4.style.display = "block";
                submitFormButton.style.display = "block"; // Show submit button
                backButton.style.display = "block"; // Show back button
            }

            // Function to open modal, reset form, and pre-fill the "Your Pledge" field
            function openModalWithPledge() {
                modal.style.display = "block"; // Open modal
                resetFormState(); // Reset to first section

                if (pledgeMessageField && !pledgeMessageField.value.trim()) {
                    pledgeMessageField.value = inputField.value.trim(); // Set the pledge field value
                }
            }

            function detectValidationError(section) {
                const invalidFields = section.querySelector(".input-validation-error");
                if (invalidFields == null) {
                    return true;
                }
                else {
                    return (Array.isArray(invalidFields) && invalidFields.length > 0);
                }
            }

            //  is called if the form is submitted
            if (resetFormState) {
                resetFormState(); // Reset form when closing modal
            }

            // Open modal when clicking submit button
            submitButton.addEventListener("click", openModalWithPledge);

            // Allow "Enter" key to open modal
            inputField.addEventListener("keypress", function (event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                    openModalWithPledge();
                }
            });

            // Handle Next button click
            nextButton.addEventListener("click", function () {
                showPage2();
            });

            // Handle Back button click
            backButton.addEventListener("click", function () {
                showPage1();
            });

            // Close Modal & Reset Form when clicking outside or closing it
            modal.addEventListener("click", function (event) {
                if (event.target === modal) {
                    modal.style.display = "none";
                    resetFormState(); // Reset form when closing modal
                }
            });

            document.querySelector(".dw_pledge_close").addEventListener("click", function () {
                modal.style.display = "none";
                resetFormState(); // Reset form when closing modal
            });

            // Close modal with Esc key
            window.addEventListener("keydown", function (event) {
                if (event.key === "Escape") {
                    modal.style.display = "none";
                    resetFormState(); // Reset form when closing modal
                }
            });
        }
    }

    // Function to move the Kentico form once it appears
    moveKenticoForm = () => {

        const modal = document.getElementById("dw_pledge_modal");
        const modalContent = document.getElementById("modal_form_placeholder");
        const kenticoForm = document.querySelector("form.form-widget");

        if (kenticoForm && !modalContent.contains(kenticoForm)) {

            modalContent.appendChild(kenticoForm);

            const observer = new MutationObserver((mutationsList, observer) => {
                this.reformatWebForm(true);
            });

            observer.observe(modalContent, { childList: true });

        }
    }

}
