import React from 'react';
import { render } from 'preact';

export class ContentCard extends React.Component {



    render(){

        var contentCard = this.props.contentCard;

        var fileType = this.getFileType(contentCard.mediaElement.url);

        return (
            <div class="dw_pledge_card dw_pledge_nas_block">

                {
                    (fileType == "image") &&
                    <img class="dw_pledge_main_image" src={contentCard.mediaElement.url} />
                }
                {
                    (fileType == "video") &&
                    <video controls>
                        <source src={contentCard.mediaElement.url} type="video/mp4" />
                        Your browser does not support the video tag.
                     </video>
                }

                <div class="dw_pledge_title">
                    <img class="dw_nas_logo" src="https://autism.org.uk/content/assets/favicon/mstile-144x144.png" />
                    <h2>{contentCard.title}</h2>
                </div>

                <div class="dw_pledge_resource_block" dangerouslySetInnerHTML={{ __html: contentCard.content }}>
                </div>
            </div>
        );
    }


    getFileType = (fileUrl) => {
        // Define common image and video extensions
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff', 'ico'];
        const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm', 'mpeg', 'mpg'];

        // Extract the file extension
        const extension = fileUrl.split('.').pop().toLowerCase();

        // Check the extension against image and video lists
        if (imageExtensions.includes(extension)) {
            return 'image';
        } else if (videoExtensions.includes(extension)) {
            return 'video';
        } else {
            return 'unknown';
        }
    }

}
