import React from 'react';
import { render } from 'preact';
import { Fragment } from 'preact';

export class SharingComponent extends React.Component {

    copyToClipboard = () => {

        var copyText = document.getElementById("engagement_share_input");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(copyText.value);
        alert("Link copied to clipboard");
        this.onShared();
    }

    componentDidMount() {

        var sharePanelHeadingText = this.props.engagementSettings.sharePanelHeadingText;
        document.getElementById("sharePanelHeadingText").innerHTML = sharePanelHeadingText;

        var sharePanelDescriptionText = this.props.engagementSettings.sharePanelDescriptionText;
        document.getElementById("sharePanelDescriptionText").innerHTML = sharePanelDescriptionText;
    }

    onShared = () => {
        this.props.onShared();
    }

    render() {

        var platformDefinitions = this.props.engagementSettings.platformDefinitions;

        var platformsWithUrls = this.getPlatformsWithUrls(platformDefinitions);
        var platformsWithNoUrl = this.getPlatformsWithNoUrl(platformDefinitions);

        var shareDescriptionText = this.props.engagementSettings.shareDescriptionText;
        var shareDescriptionTextEncoded = shareDescriptionText.replaceAll(" ", "+");
        var shareUrl = this.props.shareUrl;
        var shareUrlEncoded = encodeURIComponent(this.props.shareUrl);

        return (
            <div class="engagement_modal" style="display: block;">
                <div class="engagement_modal_content"><span class="engagement_modal_close" onclick={this.props.onShareCloseClick}>×</span>

                    <h2 id="sharePanelHeadingText" />

                    <p id="sharePanelDescriptionText" />

                    {
                        (Array.isArray(platformsWithUrls) && platformsWithUrls.length > 0) &&
                        <div class="engagement_social_icons">
                            {
                                platformsWithUrls?.map((platformDefinition) => {
                                    var platformUrl = platformDefinition.url;
                                    platformUrl = platformUrl.replaceAll("{shareUrl}", shareUrl);
                                    platformUrl = platformUrl.replaceAll("{shareUrlEncoded}", shareUrlEncoded);
                                    platformUrl = platformUrl.replaceAll("{shareDescriptionText}", shareDescriptionText);
                                    platformUrl = platformUrl.replaceAll("{shareDescriptionTextEncoded}", shareDescriptionTextEncoded);
                                    var label = "Share on " + platformDefinition.name;
                                    return (
                                        <a aria-label={label} href={platformUrl} target="_blank" onClick={this.onShared}>
                                            <img src={platformDefinition.icon} alt={platformDefinition.name} />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    }

                    {
                        (Array.isArray(platformsWithNoUrl) && platformsWithNoUrl.length > 0) &&
                        <Fragment>
                            <p><strong>Sharing on {this.getPlatformNames(platformsWithNoUrl)}?</strong></p>
                            <p>Copy the link below and then share directly on the social platform</p>
                            <div class="engagement_social_icons">
                                {
                                    platformsWithNoUrl?.map((platformDefinition) => {
                                        var label = "Share on " + platformDefinition.name;
                                        return (
                                            <a aria-label={label}>
                                                <img src={platformDefinition.icon} alt={platformDefinition.name} />
                                            </a>
                                        )
                                    })
                                }
                            </div>
                            <div class="engagement_share_link">
                                <input id="engagement_share_input" readonly="readonly" type="text" value={shareUrl} />
                                <button onclick={this.copyToClipboard}>Copy link</button>
                            </div>
                        </Fragment>
                    }

                </div>
            </div>
        );

    }

    getPlatformsWithUrls = (platformDefinitions) => {
        return platformDefinitions.filter(platformDefinition => platformDefinition.url && platformDefinition.url.trim() !== "");
    }

    getPlatformsWithNoUrl = (platformDefinitions) => {
        return platformDefinitions.filter(platformDefinition => !platformDefinition.url || platformDefinition.url == "");
    }

    getPlatformNames = (platformDefinitions) => {
        return this.arrayToCommaString(platformDefinitions, "name");
    }

    arrayToCommaString = (arr, prop) => {
        if (!arr.length) return "";
        if (arr.length === 1) return arr[0][prop];

        const allButLast = arr.slice(0, -1).map(item => item[prop]).join(", ");
        const lastItem = arr[arr.length - 1][prop];

        return `${allButLast} or ${lastItem}`;
    }
}
