import React from 'react';
import { render, Fragment } from 'preact';

import { BaseComponent } from 'project/general';
import { EngagementFunctions } from 'shared/engagement-panel';
import { PledgeCard } from './pledge-card';
import { SharingComponent } from 'shared/engagement-panel';

export class PledgePageComponent extends BaseComponent {

    pledge = null;
    pledgeLikeFunctions = null;

    static getNamespace() {
        return 'pledge-page';
    }

    static getRequiredRefs() {
        return [
            'engagementModalPlaceholder',
            'pledgePlaceholder'
        ];
    }

    onInit() {
        this.pledge = this.options.pledge;
        this.engagementFunctions = new EngagementFunctions(this.options.engagementParameters);
        this.OnRenderPledge(this.pledge);
    }

    OnRenderPledge(pledge) {
        render(
            <Fragment>

                <div class="dw_pledge_single_page_section">
                    <PledgeCard
                        key={pledge.pledgeId}
                        pledge={pledge}
                        engagementFunctions={this.engagementFunctions}
                        engagementModalPlaceholder={this.refs.engagementModalPlaceholder}
                    />
                </div>

                <div class="dw_pledge_single_page_section">

                    <div dangerouslySetInnerHTML={{ __html: this.options.engagementEncouragementText }}></div>

                    <p><button class="button button--primary" onClick={this.onShareClick}>Share this pledge</button></p>

                    <p><button class="button button--secondary" onClick={this.onGoToPledgeWallClick}>View all pledges</button></p>
                </div>

            </Fragment>
            ,
            this.refs.pledgePlaceholder
        );
    }

    onShareClick = (sender) => {
        render(
            <SharingComponent
                shareUrl={this.pledge.pledgeUrl}
                engagementSettings={this.engagementFunctions.engagementSettings}
                onShareCloseClick={this.onShareCloseClick}
                onShared={this.onShared}></SharingComponent>
            ,
            this.refs.engagementModalPlaceholder
        );
    }

    onShareCloseClick = () => {
        this.refs.engagementModalPlaceholder.innerHTML = "";
    }

    onShared = () => {
        this.engagementFunctions.onShare(this.pledge.pledgeId);
    }

    onGoToPledgeWallClick = () => {
        if (this.options.pledgeWallPageUrl != undefined && this.options.pledgeWallPageUrl != "") {
            window.location.href = this.options.pledgeWallPageUrl;
        }  
    }

}
